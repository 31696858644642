@import "custom-styles/fonts";
@import "custom-styles/icons/style.scss";
@import "custom-styles/custom-variables";
/* Reset of native styles */
@import "custom-styles/reset";
/* Grid styles */
@import "custom-styles/grid";
/* Helper clases */
@import "custom-styles/helper-classes";
/* Bootstrap buttons styles overrides */
@import "custom-styles/custom-buttons";
/* Bootstrap forms styles overrides */
@import "custom-styles/custom-forms";
/* Bootstrap modal style overrider */
@import "custom-styles/custom-modals";
/* ngx-bootstrap tooltip styles */
@import "custom-styles/ngx-bootstrap-custom-tooltip";
/* custom mixins */
@import "custom-styles/mixins/mixins";
/* custom breakpoints */
@import "src/custom-styles/breakpoints";
/* ngx-toastr custom styles */
@import "custom-styles/ngx-toastr/custom-ngx-toastr";
/* ngx-bootstrap-datepicker kpi-statistic styles overrides */
@import "custom-styles/_kpi-statistic-ngx-bootstrap-datepicker";
/* ngx-bootstrap-datepicker custom styles */
@import "custom-styles/ngx-bootstrap-datepicker";
/* ngx-file-drop custom styles */
@import "custom-styles/ngx-file-drop";
/* custom checkbox styles */
@import "custom-styles/custom-checkbox";
/* custom toggle switch styles */
@import "custom-styles/custom-toggle-switch";
/* custom range input styles */
@import "custom-styles/custom-range-input";
/* Quill editor styles and snow theme */
@import "custom-styles/quill/old-quill-style.snow-theme";
/* custom quill editor styles */
@import "custom-styles/custom-quill-editors";
/* Question styles */
@import "custom-styles/question";
/* High contrast mode styles */
@import "custom-styles/high-contrast";
/* Dashboard styles */
@import "custom-styles/dashboard";
/* kpi styles */
@import "custom-styles/kpi";
/* Virtual scroll styles*/
@import "custom-styles/recruitment-board-cdk-virtual-scroll";
/* ng-select styles*/
@import "custom-styles/ng-select";
/* ngx-toastr styles*/
@import "custom-styles/ngx-toastr";
/* ngx-quill*/
@import "../node_modules/quill/dist/quill.snow.css";

html {
  font-size: 45.5%;
}

body {
  font-size: 1.9rem;
  line-height: 1.25;
  color: $black;

  @include breakpoint-up-md {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

label {
  font-size: 1.2rem;
  line-height: 1;

  @include breakpoint-up-md {
    font-size: 2rem;
    line-height: 1.25;
  }
}

h1 {
  font-size: 2.3rem;
  line-height: 1.6;
  @include breakpoint-up-md {
    font-size: 3rem;
    line-height: 1.17;
  }
}

h2 {
  font-size: 1.9rem;
  line-height: 1.6;
  @include breakpoint-up-md {
    font-size: 2.6rem;
    line-height: 1.17;
  }
}

p, li {
  font-size: 1.8rem;
  line-height: 1.6;
}

.fullscreen-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

main {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  transition: padding-left 250ms ease-in-out;
  width: 100%;
  min-height: calc(100vh - 5.4rem);
  @include breakpoint-up-lg {
    min-height: calc(100vh - 10rem);
    padding-left: 10rem;
  }

  router-outlet ~ *:not(app-footer) {
    flex-grow: 1;
    display: block;
    section {
      max-width: 126.7rem;
      margin: 0 auto;
      padding: 0 1rem;
      @include breakpoint-up-md {
        padding: 0 1.5rem;
      }
      @include breakpoint-up-lg {
        padding: 0 2.4rem;
      }
    }
  }

  app-job-list,
  app-job-list + app-footer,
  app-job-template-list,
  app-job-template-list + app-footer,
  app-job-create,
  app-job-create + app-footer,
  app-job-edit,
  app-job-edit + app-footer,
  app-job-draft-edit,
  app-job-draft-edit + app-footer,
  app-job-template-edit,
  app-job-template-edit + app-footer,
  app-job-publish,
  app-job-publish + app-footer,
  app-dashboard,
  app-dashboard + app-footer,
  app-enterprise-dashboard,
  app-enterprise-dashboard + app-footer,
  app-company-dashboard,
  app-company-dashboard + app-footer,
  app-recruitment-board,
  app-recruitment-board + app-footer,
  app-handle-candidates,
  app-handle-candidates + app-footer,
  app-status-flow,
  app-hr,
  app-hr + app-footer,
  app-terms-and-conditions,
  app-terms-and-conditions + app-footer,
  app-company-information,
  app-company-information + app-footer,
  app-templates-list,
  app-templates-list + app-footer,
  app-email-template,
  app-email-template + app-footer,
  app-sms-template,
  app-templates,
  app-templates-list + app-footer,
  app-job-draft-list,
  app-email-reply,
  app-email-reply + app-footer,
  app-job-draft-list + app-footer,
  app-group-interviews-list,
  app-group-interviews-list + app-footer,
  app-application-interviews-list,
  app-application-interviews-list + app-footer,
  app-group-interview,
  app-group-interview + app-footer,
  app-application-interview,
  app-application-interview + app-footer,
  app-job-bind,
  app-interview-pre-join,
  app-interview-library,
  app-requisition-form-create,
  app-requisition-form-create + app-footer,
  app-requisition,
  app-requisition + app-footer,
  app-requisition-list,
  app-requisition-list + app-footer,
  app-requisition-form-list,
  app-requisition-form-list + app-footer,
  app-agreement-list,
  app-agreement-form,
  app-alternative-job-create,
  app-alternative-job-create + app-footer,
  app-alternative-job-edit,
  app-alternative-job-edit + app-footer,
  app-alternative-job-list,
  app-alternative-job-list + app-footer {
    background-color: rgba(175, 175, 175, 0.2);
  }

  app-kpi-statistics {
    background-color: rgba(175, 175, 175, 0.2);
  }
}

.custom-progress-bar-wrapper {
  width: 100%;
  height: 2rem;
  background-color: $beige;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;

  .custom-progress-bar {
    width: 0;
    height: 100%;
    transition: width 0.6s ease-in-out;
    background-color: $orange;
  }

  .percent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 2.2rem;
  }
}

// This is set if someone send email with image in content
.email-content-inner-html img {
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

// For div which is used to calculate scrollbar width
.scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Angular mentions dropdown styles override
mention-list .scrollable-menu {
  max-height: 20rem !important;
}

.panel {
  margin-bottom: 3.2rem;
  background-color: $white;
  border-radius: 1.5rem;
  padding: 1rem;
  color: $black;
  position: relative;
  @include breakpoint-up-md {
    padding: 2rem;
  }
  @include breakpoint-up-lg {
    padding: 3rem;
  }

  .panel-heading-title {
    word-break: break-word;
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.circle-img--background-tomato {
  border-radius: 50%;
  background-color: $orange;
  border: 3px solid #eb5929;
}

// Dragula CSS

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.form-disabled {
  filter: opacity(0.4);
  [ba-repeat],
  button {
    pointer-events: none;
    cursor: not-allowed;
  }
}
cdk-virtual-scroll-viewport {
  min-height: 100%;
}

// .modal-dialog {
app-job-modules {
  .job-video-questions {
    .spx-recorder {
      width: 100%;
    }
  }
}
app-video {
  &.upload-in-progress {
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1002;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(3px);
    }
  }

  .recorder {
    video {
      width: 100%;
      max-height: 400px;
    }
  }
}

.video-recorder-inactive {
  padding: 2rem;
  border-radius: 1rem;
  background: $gray;
.video-recorder-inactive {
  padding: 2rem;
  border-radius: 1rem;
  background: $gray;
  text-align: center;
}
}

.hr-settings-nav {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-back {
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    .icon {
      &:before {
        color: black;
      }
    }
  }
}

app-video-interview {
  .local-video-stream, .remote-video-stream {
    video {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.main-section {
  padding-top: 4.6rem;

  &-header {
    border-top: 2px solid $black;
    padding-bottom: 1rem;
  }

  &-title {
    @include heading-type-1-font-sizes;
    margin-bottom: 0;
    word-break: break-word;
  }
}

//quill

.ql-editor {
  min-height: 14rem;
  &.content {
    padding: 0;
    min-height: unset;
  }
}

.ql-container.ql-snow {
  border-radius: 0 0 0.7rem 0.7rem;
}

.ql-mention-list {
  background-color: white;
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  padding: 0px 0px 0px 2.8rem;
  z-index: 100;
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 1.4rem 1.4rem;
}

.ql-mention-list .ql-mention-list-item.selected {
  background-color: rgba(0, 0, 0, 0.12);
}

span.mention {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 0.3rem 0.7rem;
  border-radius: 1rem;
}

.ql-editor {
  &, td, pre {
    margin: 0;
  }
}

.ql-mention-list-container {
  max-height: 28rem;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 1rem;
  z-index: 100;
}

.ql-toolbar.ql-snow {
  border-radius: 1rem 1rem 0 0;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
}

.ql-editor ol li[data-list="bullet"]:before {
  content: '. ' !important;
}

